<template>
  <div id="deals-and-specials-view">
    <div
      class="d-flex justify-content-between align-items-center title-block border-bottom ml-3"
    >
      <PageTitleComponent
        pageTitle="Deals und Specials"
        category="Werbeinhalte"
        categoryIcon="ad"
      />
      <CreateDealsAndSpeacialsComponent />
    </div>
    <div class="pl-3 py-3">
      <div class="mt-2 alert alert-warning" v-if="dealsAndSpecials === null">
        Hier gibt es noch nichts zu sehen. Klicke auf "Erstellen" um das zu
        ändern. 😉
      </div>
      <b-tabs v-else-if="Object.keys(dealsAndSpecials).length > 0">
        <b-tab title="Aktuell" active v-if="Object.keys(activeDealsAndSpecials).length > 0">
          <table class="table mt-3">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Kategorie</th>
                <th>Position</th>
                <th>Sprache</th>
                <th>Anzeigezeitraum</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
                <DealsAndSpecialsRowComponent
                  v-for="(item, key) in activeDealsAndSpecials"
                  :key="`event-${key}`"
                  :item="item"
                  :itemKey="key"
                  v-on:selectedItem="selectItem"
                />
            </tbody>
          </table>
        </b-tab>
        <b-tab title="Abgelaufen">
          <table class="table mt-3">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Kategorie</th>
                <th>Position</th>
                <th>Sprache</th>
                <th>Anzeigezeitraum</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
                <DealsAndSpecialsRowComponent
                  v-for="(item, key) in inactiveDealsAndSpecials"
                  :key="`event-${key}`"
                  :item="item"
                  :itemKey="key"
                  v-on:selectedItem="selectItem"
                />
            </tbody>
          </table>
        </b-tab>
      </b-tabs>
      <SpinnerComponent class="mt-3" v-else />
    </div>
    <EditDealsAndSpeacialsComponent
      :modalId="'edit-deals-and-specials-modal'"
      :selectedKey="selectedKey"
      :selectedItem="selectedItem"
    />
  </div>
</template>

<script>
import PageTitleComponent from "../components/PageTitleComponent";
import CreateDealsAndSpeacialsComponent from "../components/CreateDealsAndSpeacialsComponent";
import EditDealsAndSpeacialsComponent from "../components/EditDealsAndSpeacialsComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import DealsAndSpecialsRowComponent from "../components/DealsAndSpecialsRowComponent";
import moment from "moment-timezone";

import { db } from "../plugins/firebase";
import { onValue, ref } from "firebase/database";

export default {
  name: "DealsAndSpecialsView",
  components: {
    PageTitleComponent,
    CreateDealsAndSpeacialsComponent,
    EditDealsAndSpeacialsComponent,
    SpinnerComponent,
    DealsAndSpecialsRowComponent,
  },
  methods: {
    selectItem: function (data) {
      this.selectedKey = data.key;
      this.selectedItem = data.item;
      this.$bvModal.show("edit-deals-and-specials-modal");
    },
  },
  computed: {
    activeDealsAndSpecials: function () {
      const activeDealsAndSpecials = Object.entries(
        this.dealsAndSpecials
      ).filter(([key, item]) => {
        const nowTimestamp = moment(
          moment().tz("Europe/Berlin").format("YYYY-MM-DD")
        ).unix();
        const fromTimestamp = moment(item.fromDate).unix();
        const toTimestamp = moment(item.toDate).unix();

        if (
          (fromTimestamp <= nowTimestamp && toTimestamp >= nowTimestamp) ||
          fromTimestamp >= nowTimestamp
        ) {
          return [key, item];
        }
      });

      activeDealsAndSpecials.sort((a,b) => new Date(b[1].toDate) - new Date(a[1].toDate));
      
      return Object.fromEntries(activeDealsAndSpecials);
    },
    inactiveDealsAndSpecials: function () {
      const inactiveDealsAndSpecials = Object.entries(
        this.dealsAndSpecials
      ).filter(([key, item]) => {
        const nowTimestamp = moment(
          moment().tz("Europe/Berlin").format("YYYY-MM-DD")
        ).unix();
        const toTimestamp = moment(item.toDate).unix();

        if (toTimestamp < nowTimestamp) {
          return [key, item];
        }
      });

      inactiveDealsAndSpecials.sort((a,b) => new Date(b[1].toDate) - new Date(a[1].toDate));

      return Object.fromEntries(inactiveDealsAndSpecials);
    },
  },
  mounted() {
    onValue(ref(db, "dealsAndSpecials"), (snapshot) => {
      this.dealsAndSpecials = snapshot.val()
    });
  },
  data() {
    return {
      dealsAndSpecials: [],
      selectedKey: "",
      selectedItem: {},
    };
  },
};
</script>

<style scoped>
#deals-and-specials-view {
  margin-left: 250px;
}
.title-block {
  height: 120px;
}
</style>
