<template>
  <div id="create-homepage-slide-component">
    <button class="btn btn-primary" v-b-modal="'create-homepage-slide-modal'">
      <fa-icon :icon="['fas','plus']" class="mr-2" />Erstellen
    </button>
    <b-modal id="create-homepage-slide-modal" ref="create-homepage-slide-modal" title="Slide erstellen" no-close-on-backdrop hide-header-close content-class="shadow" scrollable>
      <label for="name" class="font-weight-bold">Name</label>
      <b-form-input id="name" v-model="name" class="form-group" placeholder="Slider"></b-form-input>

      <label for="link" class="font-weight-bold">Link</label>
      <LinkComponent v-on:linkChanged="linkChanged" :linkProp="link" />

      <label for="language" class="font-weight-bold">Sprache</label>
      <b-form-select id="language" v-model="language" :options="languageOptions" class="form-group"></b-form-select>

      <label for="link" class="font-weight-bold">Shops</label>
      <p class="small">Mehrfachauswahl: CMD + Klick</p>
      <b-form-select v-model="selectedShops" :options="shops" multiple :select-size="4" class="form-group"></b-form-select>

      <label class="font-weight-bold">Anzeigezeitraum</label>
      <b-form-group
        label-cols-sm="1"
        label="Von"
        label-for="datepicker-from-date"
      >
        <b-form-datepicker id="datepicker-from-date" v-model="fromDate" :locale="'de-DE'"></b-form-datepicker>
      </b-form-group>
      <b-form-group
        label-cols-sm="1"
        label="Bis"
        label-for="datepicker-to-date"
      >
        <b-form-datepicker id="datepicker-to-date" v-model="toDate" :locale="'de-DE'"></b-form-datepicker>
      </b-form-group>

      <label class="font-weight-bold">Bild</label>
      <table class="mb-3 text-muted" cellpadding="2">
        <tbody>
          <tr>
            <td>Bildgröße (px):</td>
            <td>846×288</td>
          </tr>
          <tr>
            <td>Max. Dateigröße (mb):</td>
            <td>1</td>
          </tr>
          <tr>
            <td>Erlaubte Dateiformate:</td>
            <td>jpg, png</td>
          </tr>
        </tbody>
      </table>
      <UploadImageComponent v-on:imageUpload="imageUploaded"  :imageProp="''" />

      <template v-slot:modal-footer>
        <b-button variant="outline-secondary" v-on:click="hideModal">Abbrechen</b-button>
        <SaveButtonComponent :saveButtonSate="saveButtonSate" v-on:submitSaveButton="saveChanges" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import SaveButtonComponent from './SaveButtonComponent'
import UploadImageComponent from './UploadImageComponent'
import LinkComponent from './LinkComponent'
import moment from 'moment-timezone'

import {db} from "../plugins/firebase"
import {set, ref, push, child} from "firebase/database"

import { store } from "../plugins/store"

export default {
  name: 'CreateHomepageSlideComponent',
  components: {
    SaveButtonComponent,
    UploadImageComponent,
    LinkComponent
  },
  methods: {
    hideModal: function() {
      this.$refs['create-homepage-slide-modal'].hide()
    },
    imageUploaded: function(image) {
      this.url = image.url
      this.fileName = image.fileName
    },
    saveChanges: function() {
      if (
        this.name === '' ||
        this.fromDate === '' ||
        this.toDate === '' ||
        this.url === '' ||
        this.language === '' ||
        this.fileName === '' ||
        this.selectedShops.length === 0
      ) {
        console.log("hi");
        return
      }

      this.saveButtonSate = 'saving'

      const document = {
        name: this.name,
        link: this.link,
        fromDate: this.fromDate,
        toDate: this.toDate,
        url: this.url,
        language: this.language,
        fileName: this.fileName,
        selectedShops: this.selectedShops
      }
      const newPostKey = push(child(ref(db), 'homepageSlides')).key;
      set(ref(db, 'homepageSlides/'+newPostKey), document)
      .then(() => {
        this.saveButtonSate = 'done'
        setTimeout(() => this.hideModal(), 1800)
      })
      .catch(error => {
        this.saveButtonSate = 'error'
         store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        console.error(error)
        setTimeout(() => this.saveButtonSate = '', 1800)
      })
    },
    linkChanged: function(data) {
      this.link = data
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.name = ''
      this.link = {
        target: '_blank',
        url: '',
        intValue: '',
        type: 'Extern'
      }
      this.fromDate = ''
      this.toDate = ''
      this.url = ''
      this.fileName = ''
      this.language = 'DE'
      this.saveButtonSate = ''
      this.selectedShops = []
    })
  },
  data() {
    return {
      name: '',
      link: {
        target: '_blank',
        url: '',
        intValue: '',
        type: 'Extern'
      },
      fromDate: moment().tz('Europe/Berlin').format('YYYY-MM-DD'),
      toDate: moment().tz('Europe/Berlin').add(1, 'd').format('YYYY-MM-DD'),
      url: '',
      fileName: '',
      saveButtonSate: '',
      languageOptions: [
        'DE',
        'EN',
        'FR',
        'ES'
      ],
      language: 'DE',
      selectedShops: [],
      shops: [
        {
          text: 'CLRent',
          value: 'clrent'
        },
        {
          text: 'Retail',
          value: 'retail'
        },
        {
          text: 'Frankreich',
          value: 'fr'
        },
        {
          text: 'Großbritannien',
          value: 'uk'
        },
        {
          text: 'Standard',
          value: 'standard'
        },
        {
          text: 'Adobe Cloud Store',
          value: 'acs'
        },
        {
          text: 'Microsoft Cloud Store',
          value: 'mcs'
        },
        {
          text: 'Spanien',
          value: 'es'
        }
      ]
    }
  }
}
</script>