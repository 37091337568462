<template>
  <b-modal
    :id="modalId"
    :ref="modalId"
    title="Banner bearbeiten"
    no-close-on-backdrop
    hide-header-close
    lazy
    content-class="shadow"
    scrollable
  >
    <label for="name" class="font-weight-bold">Name</label>
    <b-form-input id="name" v-model="name" class="form-group"></b-form-input>

    <label for="link" class="font-weight-bold">Link</label>
    <LinkComponent v-on:linkChanged="linkChanged" :linkProp="link" />

    <label for="downloadsLink" class="font-weight-bold">Downloads Link</label>
    <b-form-input
      id="downloadsLink"
      v-model="downloadsLink"
      class="form-group"
      placeholder="Downloads Link"
    ></b-form-input>

    <label for="link" class="font-weight-bold">Anzeigezeitraum</label>
    <b-form-group
      label-cols-sm="1"
      label="Von"
      label-for="datepicker-from-date"
      class="mb-2"
    >
      <b-form-datepicker
        id="datepicker-from-date"
        v-model="fromDate"
        :locale="'de-DE'"
      ></b-form-datepicker>
    </b-form-group>
    <b-form-group label-cols-sm="1" label="Bis" label-for="datepicker-to-date">
      <b-form-datepicker
        id="datepicker-to-date"
        v-model="toDate"
        :locale="'de-DE'"
      ></b-form-datepicker>
    </b-form-group>

    <div>
      <p class="font-weight-bold">Aktionszeitraum ein-/ausblenden</p>
      <div class="d-flex">
        <b-form-checkbox
          id="showAktionszeitraum"
          size="md"
          v-model="showAktionszeitraum"
          switch
        ></b-form-checkbox>
        <label for="showAktionszeitraum"
          >Aktionszeitraum anzeigen
          <span v-if="showAktionszeitraum === true">&check;</span></label
        >
      </div>
    </div>

    <label class="font-weight-bold">Bild</label>
    <table class="mb-3 text-muted" cellpadding="2">
      <tbody>
        <tr>
          <td>Bildgröße (px):</td>
          <td>543x226</td>
        </tr>
        <tr>
          <td>Max. Dateigröße (mb):</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Erlaubte Dateiformate:</td>
          <td>jpg, png</td>
        </tr>
      </tbody>
    </table>
    <UploadImageComponent
      v-on:imageUpload="imageUploaded"
      :imageProp="imageUrl"
    />

    <template v-slot:modal-footer>
      <b-button variant="outline-secondary" v-on:click="hideModal"
        >Abbrechen</b-button
      >
      <SaveButtonComponent
        :saveButtonSate="saveButtonSate"
        v-on:submitSaveButton="saveChanges"
      />
    </template>
  </b-modal>
</template>

<script>
import SaveButtonComponent from "./SaveButtonComponent";
import UploadImageComponent from "./UploadImageComponent";
import LinkComponent from "./LinkComponent";

import { db } from "../plugins/firebase";
import { update, ref } from "firebase/database";

import { store } from "../plugins/store";

export default {
  name: "EditDppOfferComponent",
  props: {
    selectedKey: String,
    selectedItem: Object,
    modalId: String,
  },
  components: {
    SaveButtonComponent,
    UploadImageComponent,
    LinkComponent,
  },
  methods: {
    hideModal: function () {
      this.$refs["edit-dpp-offer-modal"].hide();
    },
    imageUploaded: function (image) {
      this.imageUrl = image.url;
      this.fileName = image.fileName;
    },
    saveChanges: function () {
      if (
        this.name === "" ||
        this.link.url === "" ||
        this.imageUrl === "" ||
        this.fileName === "" ||
        this.fromDate === "" ||
        this.toDate === ""
      ) {
        return;
      }

      this.saveButtonSate = "saving";

      const document = {
        [this.selectedKey]: {
          name: this.name,
          link: this.link,
          imageUrl: this.imageUrl,
          fileName: this.fileName,
          fromDate: this.fromDate,
          toDate: this.toDate,
          showAktionszeitraum: this.showAktionszeitraum,
          downloadsLink: this.downloadsLink,
        },
      };

      update(ref(db, "dppOffers"), document)
        .then(() => {
          this.saveButtonSate = "done";
          setTimeout(() => this.hideModal(), 1800);
        })
        .catch((error) => {
          this.saveButtonSate = "error";
          store.dispatch("fetchAlert", {
            kind: "danger",
            message: error,
            time: false,
          });
          console.error(error);
          setTimeout(() => (this.saveButtonSate = ""), 1800);
        });
    },
    linkChanged: function (data) {
      this.link = data;
    },
  },
  watch: {
    selectedItem: function (data) {
      this.name = data.name;
      this.link = data.link;
      this.imageUrl = data.imageUrl;
      this.fileName = data.fileName;
      this.fromDate = data.fromDate;
      this.toDate = data.toDate;
      this.showAktionszeitraum = data.showAktionszeitraum;
      this.downloadsLink = data.downloadsLink;
    },
  },
  mounted() {
    this.$root.$on("bv::modal::hidden", () => {
      this.saveButtonSate = "";
    });
  },
  data() {
    return {
      name: "",
      link: {
        target: "_blank",
        url: "",
        intValue: "",
        body: "",
        subject: "",
        type: "Extern",
      },
      imageUrl: "",
      fileName: "",
      saveButtonSate: "",
      fromDate: "",
      toDate: "",
      showAktionszeitraum: false,
      downloadsLink: "",
    };
  },
};
</script>
