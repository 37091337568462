<template>
  <div id="imprint-view">
    <div class="d-flex justify-content-between align-items-center title-block border-bottom ml-3">
      <PageTitleComponent pageTitle="Impressum" category="Texte" categoryIcon="align-left" />
    </div>
    <div class="pl-3 py-3">
      <b-tabs content-class="mt-3">
        <b-tab title="Deutsch" active>
          <TextEditorComponent :shop="'de'" :textType="'imprint'" />
        </b-tab>
        <b-tab title="Englisch">
          <TextEditorComponent :shop="'en'" :textType="'imprint'" />
        </b-tab>
        <b-tab title="Französisch">
          <TextEditorComponent :shop="'fr'" :textType="'imprint'" />
        </b-tab>
        <b-tab title="Spanisch">
          <TextEditorComponent :shop="'es'" :textType="'imprint'" />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from '../components/PageTitleComponent'
import TextEditorComponent from '../components/TextEditorComponent'

export default {
  name: 'ImprintView',
  components: {
    PageTitleComponent,
    TextEditorComponent
  }
}
</script>

<style scoped>
#imprint-view {
  margin-left: 250px;
}
.title-block {
  height: 120px;
}
</style>