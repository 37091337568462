import {initializeApp} from 'firebase/app';
import {connectAuthEmulator, getAuth} from "firebase/auth";
import {getDatabase, connectDatabaseEmulator} from "firebase/database";
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyABiYnrIXWVfmEz9kvHxzhS3jExTokTfxU",
  authDomain: "clgmbh-shopadmin.firebaseapp.com",
  databaseURL: "https://clgmbh-shopadmin.firebaseio.com",
  projectId: "clgmbh-shopadmin",
  storageBucket: "clgmbh-shopadmin.appspot.com",
  messagingSenderId: "938564660150",
  appId: "1:938564660150:web:2ba6bce2d2be8d5f03af17",
  measurementId: "G-RMEW6KJDDG"
};

const region = 'europe-west3';

location.hostname === 'localhost' && (self.FIREBASE_APPCHECK_DEBUG_TOKEN = true)

const firebase = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(firebase, {
  provider: new ReCaptchaV3Provider('6LdYsKcgAAAAAOuvg5ytwp8JeGuAIdV7SHn1dM5E'),
  isTokenAutoRefreshEnabled: true
});

const auth = getAuth(firebase);
const db = getDatabase(firebase);
const functions = getFunctions(firebase, region);
const storage = getStorage(firebase);

if (location.hostname === 'localhost') {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectDatabaseEmulator(db, 'localhost', 9002);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectStorageEmulator(storage, "localhost", 9199);
}

export {firebase, auth, functions, storage, db, region, appCheck} 